import React from "react"
import Layout from "../components/layout.component"
import Header from "../components/header.component"
import { graphql } from "gatsby"
import { linkFragment, linkResolver } from "../link-resolver"
import { isDark } from "../utils/styles"
import { RichText } from "prismic-reactjs"
import { Slices } from "../components/slices.component"
import { Section } from "../components/common/section.component"
import { AsideNavigation } from "../components/common/aside-navigation.component"
import StickyBox from "react-sticky-box"
import SEO from "../components/common/seo.component"

const ContentPage = ({ data, uri }) => {
  const page = data.prismic.allContent_pages.edges[0]

  if (page) {
    const dark = isDark(page.node.background_color, page.node.background_image)
    const aside_navigation = data.prismic.allAsideNavigations.edges.find(
      item =>
        page.node.aside_navigation &&
        item.node._meta.uid === page.node.aside_navigation._meta.uid
    )
    return (
      <Layout>
        <SEO
          title={`ANKO | ${page.node.page_title ||
            (page.node.title ? page.node.title[0].text : "") ||
            "Technology"}`}
          description={page.node.description || page.node.text}
          keywords={page.node.keywords}
          image={page.node.preview_image}
          lang={page.node._meta.lang}
          canonical={uri}
        />
        <Section
          className="text-center"
          backgroundImage={page.node.background_image}
          backgroundColor={page.node.background_color}
        >
          <Header theme={dark ? "dark" : "light"} />
          <div className="content-page-title">
            <RichText render={page.node.title} linkResolver={linkResolver} />
          </div>
          <div className="content-page-text">
            <RichText render={page.node.text} linkResolver={linkResolver} />
          </div>
        </Section>
        {aside_navigation ? (
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-mb-12 text-lg-left text-center">
                <StickyBox offsetTop={80} offsetBottom={20}>
                  <AsideNavigation navigation={aside_navigation.node} />
                </StickyBox>
              </div>
              <div className="col-lg-8 col-mb-12">
                <Slices body={page.node.body} />
              </div>
            </div>
          </div>
        ) : (
          <Slices body={page.node.body} />
        )}
      </Layout>
    )
  }
  return null
}
ContentPage.fragments = [linkFragment]
export default ContentPage

export const query = graphql`
  query contentPageQuery($uid: String, $aside_id: String) {
    prismic {
      allContent_pages(uid: $uid) {
        edges {
          node {
            __typename
            page_title
            description
            keywords {
              keyword
            }
            preview_image
            _meta {
              uid
              type
              lang
            }
            background_image
            background_color
            text
            title
            aside_navigation {
              ... on PRISMIC_AsideNavigation {
                _meta {
                  uid
                }
              }
            }
            parent {
              ... on PRISMIC_Content_page {
                _meta {
                  type
                  uid
                }
                parent {
                  ... on PRISMIC_Content_page {
                    _meta {
                      type
                      uid
                    }
                  }
                }
              }
            }
            body {
              ... on PRISMIC_Content_pageBodyGallery {
                type
                label
                primary {
                  anchor
                  bgColor
                  bgImage
                  text
                  title
                }
                fields {
                  image
                  text
                  title
                }
              }
              ... on PRISMIC_Content_pageBodyItems_collection {
                label
                type
                primary {
                  bg_color
                  bg_image
                  text
                  title
                  link_style
                  link_text
                }
                fields {
                  tag
                }
              }
              ... on PRISMIC_Content_pageBodyPricing_plans {
                type
                label
                fields {
                  price_units
                  plan_price
                  plan_name
                  plan_features
                  link_text
                  link_style
                  link {
                    ...link
                  }
                  is_free_plan
                }
                primary {
                  bg_image
                  bg_color
                  title
                  text
                }
              }
              ... on PRISMIC_Content_pageBodyText {
                type
                label
                primary {
                  text
                  bg_color
                  bg_image
                }
              }
              ... on PRISMIC_Content_pageBodyFeature {
                type
                label
                primary {
                  bg_color
                  bg_image
                  text
                }
                fields {
                  image
                  link_style
                  link_text
                  text
                  title
                  link {
                    ...link
                  }
                }
              }
              ... on PRISMIC_Content_pageBodyBlock_with_text_and_image {
                label
                type
                primary {
                  bg_color
                  bg_image
                  min_height
                  title
                  text
                  image
                  link {
                    ...link
                  }
                  link_style
                  link_text
                }
              }
              ... on PRISMIC_Content_pageBodyForm {
                type
                label
                primary {
                  bg_color
                  bg_image
                  form_script
                  form_url
                  text
                }
              }
            }
          }
        }
      }
      allAsideNavigations(uid: $aside_id) {
        edges {
          node {
            _meta {
              uid
            }
            text
            body {
              ... on PRISMIC_AsideNavigationBodyMenu {
                label
                type
                primary {
                  nav_text
                }
                fields {
                  icon
                  link {
                    ...link
                  }
                  link_style
                  link_text
                }
              }
            }
          }
        }
      }
    }
  }
`
